import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Button, Pagination, Typography, Stamp, Tooltip } from '@bayon/commons';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Documento from '@bayon/svg-icons/icons/FileAlt';
import ArrowLongRight from '@bayon/svg-icons/icons/ArrowLongRight';
import withWidth from '@material-ui/core/withWidth';
import FilePage from '@bayon/svg-icons/icons/FilePage';
import * as actions from './ConsultaProcesso.epics';
import { PAGE_LIMIT, BASE_URL } from '../../commons/constants';
import { formatarNumeroMP } from '../../commons/mascaras';
import request from '../../utils/request';
import { descricaoTipoRelatorio, getTextoCabecalho, getDatasExibicao, getClassName, getHintDocumento,
  TipoRelatorioEnum, getListaParteTratada, getUserResponsavel, getDataVista, montarRequisicao } from '../../utils/utils';
import { exibeTipoProcesso, exibeHintTipoProcesso, exibeLinkPastaDigital, exibeRodape,
  exibeProcessoComoLink, exibeResultadosMesmaPagina, exibeMembroResponsavel, exibeDataVista,
  formataNomeExibicaoDoc, getDocs } from '../../utils/funcoesConsultaProcesso';
import PopperParteRepresentante from '../../components/popperParteRepresentante/PopperParteRepresentante';
import PopperLinksDocs from '../../components/popperLinksDocs/PopperLinksDocs';
import moment from 'moment';
import SockJS from 'sockjs-client';

const styles = () => ({
  root: { flexGrow: 1, listStyle: 'none', margin: 0, padding: 0 },
  colorPadding: { paddingTop: 25, paddingLeft: 25, paddingRight: 25, backgroundColor: '#FFFFFF' },
  colorPaddingInterna: { padding: 20, minHeight: 100, backgroundColor: '#F2F2F2' },
  unjLabel: { marginBottom: 0, fontSize: '12px', fontWeight: 'lighter', color: '#919191', lineHeight: '20px' },
  unjListRow: { listStyle: 'none', margin: 0, padding: 0 },
  li: { padding: 10, backgroundColor: '#F2F2F2', marginBottom: 0, minHeight: 100 },
  col: { padding: 10 },
  colCard: { padding: 5 },
  divLinha: { padding: 10, alignItems: 'center !important' },
  caption: { fontSize: 12 },
  footer: { padding: 5, marginBottom: 15, minHeight: 20, backgroundColor: '#E9E9E9' },
  altura: { minHeight: '100vh' },
});

function ConsultaProcesso(props) {
  const { classes, processos, rowCount, totalPages, cdOrgao, numeroMP, cdSituacao, tipoRelatorio,
    parteInteressada, fetchChangePage, history, paginaAtual, setPaginaAtual, dataInicial, dataFinal,
    iniciarCarregamento, setDadosCabecalho, consultarVinculos, consultarMovimentacoes,
    consultarDatasMovimentacoes, consultarPartes, consultarTramitacoes, consultarDistribuicoes,
    terminarCarregamento, width, tipoProcesso, setPopperIsOpen, popperIsOpen, setFecharTodos,
    fecharTodos } = props;

  const [state, setState] = useState({ page: paginaAtual });

  const [desabilitaExportar, setDesabilitaExportar] = useState(false);

  let docs = [];

  async function changePage(page) {
    if (page <= 0 || page > totalPages) { return; }
    await fetchChangePage({ pagina : page - 1, cdOrgao, numeroMP, cdSituacao, tipoRelatorio,
      parteInteressada, dataInicial, dataFinal, tipoProcesso });
    setState({ page: page});
    setPaginaAtual(page);
    window.parent.scroll(0, 0);
  }

  useEffect(() => { window.parent.scroll(0, 0); }, []);

  function goToDetail(ev, item) {
    ev.preventDefault();
    const cdProcesso = item.codigo_processo;
    request.all([
      iniciarCarregamento(),
      history.push('/portaltransparencia/detalhe'),
      setDadosCabecalho(item, state.page),
      consultarVinculos(cdProcesso),
      consultarMovimentacoes(cdProcesso, tipoRelatorio),
      consultarDatasMovimentacoes(cdProcesso),
      consultarPartes(cdProcesso),
      consultarTramitacoes(cdProcesso),
      consultarDistribuicoes(cdProcesso),
    ]).then(request.spread(() => {
      terminarCarregamento();
    }));
  }

  function novaConsulta() {
    setPaginaAtual(1);
    switch (localStorage.getItem('tipoRelatorio')) {
      case '1': history.push('/portaltransparencia/procedimentopreparatorio'); break;
      case '2': history.push('/portaltransparencia/inqueritocivil'); break;
      case '3': history.push('/portaltransparencia/procedimentoinvestigatorio'); break;
      case '4': history.push('/portaltransparencia/inqueritoPolicial'); break;
      case '5': history.push('/portaltransparencia/termoajustamentoconduta'); break;
      case '6': history.push('/portaltransparencia/recomendacoesexpedidas'); break;
      case '7': history.push('/portaltransparencia/resolucao-89'); break;
      case '8': history.push('/portaltransparencia/termoajustamentoconduta-resolucao-2-2011'); break;
      case '9': history.push('/portaltransparencia/inqueritocivil-resolucao-2-2011'); break;
      case '10': history.push('/portaltransparencia/consulta-distribuicao-110-2014'); break;
      case '11': history.push('/portaltransparencia/audienciaspublicas-resolucao-92'); break;
      default: history.push('/portaltransparencia/procedimentopreparatorio');
    }
  }

  async function exportarRelatorio() {
    setDesabilitaExportar(true);

    let URL_SOCKET = `${BASE_URL}/socket`;

    var sock = new SockJS(URL_SOCKET);

    sock.onmessage = function(e) {
      const arquivo = e.data;
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(arquivo)}`;
      downloadLink.download = 'processos.csv';
      downloadLink.click();
      sock.close();
    };

    sock.onclose = function() {
      setDesabilitaExportar(false);
    };

    sock.onerror = function() {
      setDesabilitaExportar(false);
    };

    sock.onopen = function() {
      const req = montarRequisicao({ cdOrgao, numeroMP, cdSituacao, tipoRelatorio,
        parteInteressada, dataInicial, dataFinal, tipoProcesso });
      sock.send(JSON.stringify(req.body));
    };
  }

  function fechar() {
    if (popperIsOpen) {
      setPopperIsOpen();
    }
  }

  return (
    <Fragment>
      <div className={classes.altura} onClick={fechar}>
        <div className={classes.colorPadding}>
          {!exibeResultadosMesmaPagina(tipoRelatorio) &&
          <div className="row">
            <div className="float-left" style={{ marginLeft: 15, marginBottom: 10 }}>
              <Typography size={21} weight="semibold">{getTextoCabecalho(tipoProcesso).textoCabecalhoGrande}</Typography>
            </div>
            <div className="float-left" style={{ marginLeft: 15, paddingTop: 8, paddingBottom: 15 }}>
              <Typography size={13} color="neutralSecondaryAlt">{getTextoCabecalho(tipoProcesso).textoCabecalhoPequeno}</Typography>
            </div>
          </div>}
          <div className="row" style={{ paddingBottom: 20 }}>
            <div className="col-md-8">
              <Typography size={12} color="neutralSecondaryAlt">{rowCount} {descricaoTipoRelatorio()}</Typography>
            </div>
            <div className="col-md-4" align="right">
              <div className="float-right">
                <Button
                  id="btnVoltar"
                  name="btnVoltar"
                  aria-label="Nova consulta"
                  size="medium"
                  variant="subtle"
                  icon="Search"
                  hidden={exibeResultadosMesmaPagina(tipoRelatorio) && "hiden"}
                  onClick={novaConsulta}
                >Nova consulta
                </Button>
              </div>
              <div className="float-right">
                <Button
                  id="btnExportar"
                  name="btnExportar"
                  aria-label={desabilitaExportar ? "Exportando.." : "Exportar relatório"}
                  size="medium"
                  variant="subtle"
                  icon="FileAlt"
                  onClick={exportarRelatorio}
                  disabled={desabilitaExportar || !processos.length}
                >{desabilitaExportar ? "Exportando.." : "Exportar relatório"}
                </Button>
              </div>
            </div>
          </div>
          <div className={classes.root}>
            {!processos.length > 0 && (
            <div>
              <br />
              <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
                <Documento style={{ height: 64, width: 64 }} />
                <div style={{ marginLeft: '-43%' }}>
                  <Typography size={16}>Nenhum resultado encontrado ou a pesquisa informada não pode ser consultada neste relatório.</Typography>
                </div>
              </div>
            </div>
            )}
            {processos.map(item => (
              <>
                <Grid key={item.numero_processo} container className={classes.li}>
                  <Grid item xs={12} sm={exibeLinkPastaDigital() ? 2 : 3} className={classes.col}>
                    <div>
                      <Stamp
                        label={item.descricao_situacao}
                        textColor="#ffffff"
                        backgroundColor="#5B8FC7"
                      />
                    </div>
                    <div>
                      {exibeProcessoComoLink() && (
                      <Tooltip
                        placement="top"
                        label="Detalhes do Processo"
                        aria-label="Detalhes do Processo"
                      >
                        <Link id="linkProcesso" to="#" onClick={ev => goToDetail(ev, item)}>
                          <Typography size={14}>{item.numero_processo ? formatarNumeroMP(item.numero_processo) : ''}</Typography>
                        </Link>
                      </Tooltip>
                      )}
                      {!exibeProcessoComoLink() && (
                      <>
                        <Typography size={14}>{item.numero_processo ? formatarNumeroMP(item.numero_processo) : ''}</Typography>
                        {exibeTipoProcesso() && (
                        <Typography size={12} color="neutralSecondaryAlt">{item.tipo_processo}</Typography>
                        )}
                        {exibeHintTipoProcesso(item.ultima_mov_tac_rec) && (
                        <>
                          <Tooltip
                            placement="top"
                            label={`${JSON.parse(item.ultima_mov_tac_rec).tipo} lançado em ${JSON.parse(item.ultima_mov_tac_rec).data}`}
                            aria-label={`${JSON.parse(item.ultima_mov_tac_rec).tipo} lançado em ${JSON.parse(item.ultima_mov_tac_rec).data}`}
                          >
                            <Typography size={12}><ArrowLongRight />&nbsp;&nbsp;{JSON.parse(item.ultima_mov_tac_rec).tipo}</Typography>
                          </Tooltip>
                        </>
                        )}
                      </>
                      )}
                    </div>
                  </Grid>
                  {![TipoRelatorioEnum.CONSULTA_DISTRIBUICAO_110_2014, TipoRelatorioEnum.AUDIENCIAS_PUBLICAS_RESOLUCAO_92].includes(tipoRelatorio) && (
                  <Grid item xs={12} sm={3} className={classes.col}>
                    <div className={classes.unjLabel}> Assunto </div>
                    <div>{item.descricao_assunto}</div>
                  </Grid>
                  )}
                  {[TipoRelatorioEnum.AUDIENCIAS_PUBLICAS_RESOLUCAO_92].includes(tipoRelatorio) &&
                    item.lista_movimentacoes && item.lista_movimentacoes.length &&
                  (
                  <Grid item xs={12} sm={3} className={classes.col}>
                    <div className={classes.unjLabel}> Data da Movimentação </div>
                    <div>{moment(item.lista_movimentacoes[item.lista_movimentacoes.length - 1].data_movimento).utc(false).format('DD/MM/YYYY HH:mm')}</div>
                  </Grid>
                  )}
                  {[TipoRelatorioEnum.AUDIENCIAS_PUBLICAS_RESOLUCAO_92].includes(tipoRelatorio) && (
                  <Grid item xs={12} sm={3} className={classes.col}>
                    <div className={classes.unjLabel}> Tipo de cadastro </div>
                    <div>{item.tipo_processo}</div>
                  </Grid>
                  )}
                  {[TipoRelatorioEnum.CONSULTA_DISTRIBUICAO_110_2014].includes(tipoRelatorio) && (
                  <Grid item xs={12} sm={3} className={classes.col}>
                    <div className={classes.unjLabel}> Classe </div>
                    <div>{item.descricao_classe}</div>
                  </Grid>
                  )}
                  <Grid item xs={12} sm={3} className={classes.col}>
                    <div className={classes.unjLabel}> Órgão </div>
                    <div>{item.descricao_local}</div>
                  </Grid>
                  {![TipoRelatorioEnum.AUDIENCIAS_PUBLICAS_RESOLUCAO_92].includes(tipoRelatorio) && (
                  <Grid item xs={12} sm={3} className={classes.col}>
                    <div className={classes.unjLabel}> Parte interessada </div>
                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{item.primeira_parte_ativa}</div>
                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{item.primeira_parte_passiva}</div>

                    <PopperParteRepresentante
                      popperIsOpen={popperIsOpen}
                      fecharTodos={fecharTodos}
                      callbackIsOpen={setPopperIsOpen}
                      callbackFecharTodos={setFecharTodos}
                      listaPartes={getListaParteTratada(item.lista_partes ? item.lista_partes : [])}
                    />

                  </Grid>
                  )}
                  {exibeLinkPastaDigital() && (
                  <Grid container xs={1} className={classes.col} justify="center" alignItems="center">
                    <Tooltip
                      placement="top"
                      label={getHintDocumento(item.url_documento)}
                      aria-label={getHintDocumento(item.url_documento)}
                    >
                      <a href={item.url_documento} target="_blank" rel="noopener noreferrer">
                        <FilePage fill={item.url_documento ? '#0078D7' : '#A6A6A6'} style={{ height: 19, width: 25 }} />
                      </a>
                    </Tooltip>
                  </Grid>
                  )}
                  { (docs = getDocs(item)) && (docs.length > 0) &&(
                  <Grid item xs={12} sm={3} className={classes.col}>
                    <Tooltip
                      placement="top"
                      label="Visualizar documento"
                      aria-label="Visualizar documento"
                    >
                    <a href={docs[0].url_documento} target="_blank" rel="noopener noreferrer">
                      <FilePage fill= '#0078D7' style={{ width: 21}} />
                      { formataNomeExibicaoDoc(docs[0].descricao_tipo_movimentacao, docs[0].data_movimento)}
                    </a>
                    </Tooltip>
                    { (Object.keys(docs).length > 1) && (
                      <PopperLinksDocs
                        popperIsOpen={popperIsOpen}
                        fecharTodos={fecharTodos}
                        callbackIsOpen={setPopperIsOpen}
                        callbackFecharTodos={setFecharTodos}
                        lista={docs.slice(1)}
                      />) }
                  </Grid> )}
                </Grid>
                <div style={{ marginBottom: 15 }}>
                  <Grid key={processos.indexOf(item)} container className={classes.footer} style={{ display: exibeRodape(item.datas_processo, item.ultima_mov_tac_rec) }}>
                    <Grid item xs={12} sm={12}>
                      <div>
                        <div className={getClassName(width)} style={{ marginLeft: 15 }}>
                          <Typography size={10} color="neutralSecondaryAlt">{getDatasExibicao(item.datas_processo, 'INS', 'Instauração')}</Typography>
                        </div>
                        <div className={getClassName(width)} style={{ marginLeft: 15 }}>
                          <Typography size={10} color="neutralSecondaryAlt">{getDatasExibicao(item.datas_processo, 'HOM', 'Homologação Arquivamento')}</Typography>
                        </div>
                        <div className={getClassName(width)} style={{ marginLeft: 15 }}>
                          <Typography size={10} color="neutralSecondaryAlt">{getDatasExibicao(item.datas_processo, 'ARQ', 'Promoção Arquivamento')}</Typography>
                        </div>
                        <div className={getClassName(width)} style={{ marginLeft: 15 }}>
                          <Typography size={10} color="neutralSecondaryAlt">{getDatasExibicao(item.ultima_mov_tac_rec, 'ASS', 'Assinatura do TAC')}</Typography>
                        </div>
                        <div className={getClassName(width)} style={{ marginLeft: 15 }}>
                          <Typography size={10} color="neutralSecondaryAlt">{getDatasExibicao(item.data_remessa, 'REM', 'Remetido/Distribuído')}</Typography>
                        </div>
                        {exibeMembroResponsavel() && item.usuario_responsavel &&
                            <div className={getClassName(width)} style={{ marginLeft: 15 }}>
                              <Typography size={10} color="neutralSecondaryAlt">{getUserResponsavel(item.usuario_responsavel, ';')}</Typography>
                            </div>
                        }
                        {exibeDataVista() && item.usuario_responsavel &&
                            <div className={getClassName(width)} style={{ marginLeft: 15 }}>
                              <Typography size={10} color="neutralSecondaryAlt">{getDatasExibicao(getDataVista(item.usuario_responsavel, ';'), 'MOV', 'Data de Vistas')}</Typography>
                            </div>
                        }
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </>
            ))}
          </div>
        </div>
        {rowCount > 10 && (
        <div style={{ marginLeft: 25, marginRight: 25 }}>
          <div style={{ backgroundColor: '#EAEAEA', padding: 10, marginTop: 5 }}>
            <Pagination
              id="paginacaoProcesso"
              variant="pages"
              nameItem="Processos"
              onChangePage={changePage}
              count={rowCount}
              countPerPage={PAGE_LIMIT}
              page={paginaAtual}
            />
          </div>
        </div>
        )}
      </div>
    </Fragment>
  );
}

ConsultaProcesso.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  processos: PropTypes.array.isRequired,
  totalPages: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  cdOrgao: PropTypes.string.isRequired,
  numeroMP: PropTypes.string.isRequired,
  cdSituacao: PropTypes.string.isRequired,
  tipoRelatorio: PropTypes.number.isRequired,
  parteInteressada: PropTypes.string.isRequired,
  fetchChangePage: PropTypes.func.isRequired,
  paginaAtual: PropTypes.number.isRequired,
  setPaginaAtual: PropTypes.func.isRequired,
  dataInicial: PropTypes.instanceOf(Date).isRequired,
  dataFinal: PropTypes.instanceOf(Date).isRequired,
  iniciarCarregamento: PropTypes.func.isRequired,
  setDadosCabecalho: PropTypes.func.isRequired,
  consultarVinculos: PropTypes.func.isRequired,
  consultarMovimentacoes: PropTypes.func.isRequired,
  consultarDatasMovimentacoes: PropTypes.func.isRequired,
  consultarPartes: PropTypes.func.isRequired,
  consultarTramitacoes: PropTypes.func.isRequired,
  consultarDistribuicoes: PropTypes.func.isRequired,
  terminarCarregamento: PropTypes.func.isRequired,
  fetchArquivo: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  tipoProcesso: PropTypes.number.isRequired,
  setPopperIsOpen: PropTypes.func.isRequired,
  popperIsOpen: PropTypes.bool.isRequired,
  setFecharTodos: PropTypes.func.isRequired,
  fecharTodos: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  rowCount: state.pesquisaProcessoReducer.rowCount,
  processos: state.pesquisaProcessoReducer.processos,
  totalPages: state.pesquisaProcessoReducer.totalPages,
  cdOrgao: state.pesquisaProcessoReducer.cdOrgao,
  numeroMP: state.pesquisaProcessoReducer.numeroMP,
  cdSituacao: state.pesquisaProcessoReducer.cdSituacao,
  tipoRelatorio: state.pesquisaProcessoReducer.tipoRelatorio,
  parteInteressada: state.pesquisaProcessoReducer.parteInteressada,
  paginaAtual: state.consultaProcessoReducer.paginaAtual ? state.consultaProcessoReducer.paginaAtual : 1,
  dataInicial: state.pesquisaProcessoReducer.dataInicial,
  dataFinal: state.pesquisaProcessoReducer.dataFinal,
  tipoProcesso: state.pesquisaProcessoReducer.tipoProcesso,
  popperIsOpen: state.consultaProcessoReducer.popperIsOpen,
  fecharTodos: state.consultaProcessoReducer.fecharTodos,
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(ConsultaProcesso)));
